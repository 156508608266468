import { Grid, Typography } from '@material-ui/core';
import { Faq } from '@tripr/common';
import React from 'react';
import { Field } from 'react-final-form';
import { FormList } from '../../common/FormList';
import { MyTextField } from '../../common/forms/TextField';

export const FaqList: React.FC<{ name: string }> = ({ name }) => (
  <FormList<Faq>
    name={name}
    newValue={() => ({ question: '', answer: '' })}
    render={p => (
      <Grid container spacing={2}>
        {p.index !== undefined && p.index > -1 ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '0.6rem',
              padding: '1rem',
              width: '5.4rem ',
              height: '3.5rem',
              marginTop: '0.52rem',
              backgroundColor: 'rgba(0, 0, 0, 0.09)',
            }}
          >
            <Typography variant="h5" style={{ opacity: 0.7 }}>
              {p.index !== undefined && p.index > -1 ? p.index + 1 : 0}
            </Typography>
          </div>
        ) : null}
        <Field component={MyTextField} name={`${p.parentName}.question`} label="Question" xs={10} variant={'filled'} />
        <Field component={MyTextField} name={`${p.parentName}.answer`} label="Answer" xs={12} variant={'filled'} multiline />
      </Grid>
    )}
  />
);
